.main-header .logo {
  padding: 0 10px !important
}

.sidebar-toggle svg {
  display: none; /* Hide the existing SVG */
}

.dropdown-sidebar .dropdown-toggle span {
  display: none;  
}

.dropdown-menu .footer > div {
  cursor: pointer;
}

.sidebar-collapse .sidebar-toggle::before {
  content: '';
  display: inline-block;
  width: 15px; /* Set width according to your image */
  height: 15px; /* Set height according to your image */
  background-image: url('../public/angle-right-solid.svg'); /* Replace with your custom icon */
  background-size: contain;
  background-repeat: no-repeat;
}

.control-sidebar2 {
  padding-top: 10px !important
}

.sidebar-toggle::before {
  content: '';
  display: inline-block;
  width: 15px; /* Set width according to your image */
  height: 15px; /* Set height according to your image */
  background-image: url('../public/angle-left-solid.svg'); /* Replace with your custom icon */
  background-size: contain;
  background-repeat: no-repeat;
}

/*
 * Skin: Red-custom
 * ---------
 */

.skin-red-custom .main-header .navbar {
  background-color: #eb0034;
}
.skin-red-custom .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-red-custom .main-header .navbar .nav > li > a:hover,
.skin-red-custom .main-header .navbar .nav > li > a:active,
.skin-red-custom .main-header .navbar .nav > li > a:focus,
.skin-red-custom .main-header .navbar .nav .open > a,
.skin-red-custom .main-header .navbar .nav .open > a:hover,
.skin-red-custom .main-header .navbar .nav .open > a:focus,
.skin-red-custom .main-header .navbar .nav > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-red-custom .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-red-custom .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-red-custom .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-red-custom .main-header .navbar .sidebar-toggle:hover {
  background-color: #eb0034;
}
@media (max-width: 767px) {
  .skin-red-custom .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-red-custom .main-header .navbar .dropdown-menu li a {
    color: #fff;
  }
  .skin-red-custom .main-header .navbar .dropdown-menu li a:hover {
    background: #eb0034;
  }
}
.skin-red-custom .main-header .logo {
  background-color: #eb0034;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-red-custom .main-header .logo:hover {
  background-color: #eb0034;
}
.skin-red-custom .main-header li.user-header {
  background-color: #eb0034;
}
.skin-red-custom .content-header {
  background: transparent;
}
.skin-red-custom .wrapper,
.skin-red-custom .main-sidebar,
.skin-red-custom .left-side {
  background-color: #222d32;
}
.skin-red-custom .user-panel > .info,
.skin-red-custom .user-panel > .info > a {
  color: #fff;
}
.skin-red-custom .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226;
}
.skin-red-custom .sidebar-menu > li > a {
  border-left: 3px solid transparent;
}
.skin-red-custom .sidebar-menu > li:hover > a,
.skin-red-custom .sidebar-menu > li.active > a,
.skin-red-custom .sidebar-menu > li.menu-open > a {
  color: #ffffff;
  background: #1e282c;
}
.skin-red-custom .sidebar-menu > li.active > a {
  border-left-color: #eb0034;
}
.skin-red-custom .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41;
}
.skin-red-custom .sidebar a {
  color: #b8c7ce;
}
.skin-red-custom .sidebar a:hover {
  text-decoration: none;
}
.skin-red-custom .sidebar-menu .treeview-menu > li > a {
  color: #8aa4af;
}
.skin-red-custom .sidebar-menu .treeview-menu > li.active > a,
.skin-red-custom .sidebar-menu .treeview-menu > li > a:hover {
  color: #ffffff;
}
.skin-red-custom .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px 10px;
}
.skin-red-custom .sidebar-form input[type="text"],
.skin-red-custom .sidebar-form .btn {
  box-shadow: none;
  background-color: #374850;
  border: 1px solid transparent;
  height: 35px;
}
.skin-red-custom .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-red-custom .sidebar-form input[type="text"]:focus,
.skin-red-custom .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666;
}
.skin-red-custom .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff;
}
.skin-red-custom .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* https://stackoverflow.com/questions/18422223/bootstrap-3-modal-vertical-position-center */
.modal {
  text-align: center;
  padding: 0 !important;
}

.content-wrapper {
  min-height: 100vh !important;
}


.modal:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px; /* Adjusts for spacing */
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}



@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap");
@import "~antd/dist/reset.css";

:root {
  --text-color: #001529;
  --twitter: #1da1f2;
  --linkedin: #0077b5;
  --github: #333333;
  --website: #1890ff;
  --gmail: #db4437;
  --white: #ffffff;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}


/* Layout CSS */
.ant-layout {
  background: var(--white);
}

.ant-layout-header,
.ant-layout-footer {
  text-align: center;
  background: var(--text-color);
  color: var(--white);
}


.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: #3c8dbc;
  cursor: pointer;
  color: #fff;
}

.table-condensed td, .table-condensed tr {
  padding: 3px !important;
}


.input-transparent {
  padding: 1px;
  display: block;
  outline: none;
  border: none;
  border-bottom: 0px solid #808080;
  width: 100%;
  background: inherit;
}


.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid black;
  padding: 20px;
  z-index: 1000;
}

.popup-content {
  text-align: center;
}

.popup button {
  margin-top: 10px;
}


.unstyled-button {
  border: none !important;
  background: none !important;
   background-color: #fff !important;
  color: #333 !important;
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.sidebar-button {
  background: transparent !important;
  border: none !important;
  color: #b8c7ce !important; 
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}


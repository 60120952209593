/* src/Login.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  font-family: Arial, sans-serif;
}

.logo-form {
  width: 50px;
  margin-bottom: 10px;
}

.login-form {
  width: 300px;
}

.keycloak-login {
  background-color: #f0f0f0;
  color: #4e62d8;
  border: 1px solid #4e62d8;
}

.keycloak-login:hover {
  background-color: #e0e0e0;
}

.login-alert {
  margin-bottom: 20px;
}
/* UnitForm.css */

.warning-summary p {
  margin: 0;
}

/* Warning Summary */
.warning-summary {
  background-color: #fff3cd; /* Change to yellowish background */
  color: #856404; /* Dark yellow/orange text */
  border: 1px solid #ffeeba; /* Yellow border */
  padding: 10px;
  margin-top: 10px;
  font-size: 0.9em;
}

/* CustomCheckbox.css */
.angle-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .checkbox-input {
    display: none; /* Hide the default checkbox */
  }
  
  .checkbox-angle {
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    transition: background-color 0.3s, border-color 0.3s;
    margin-left: 8px; /* Space between label text and checkbox */
  }

  